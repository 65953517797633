import './PasswordResetNextStepsForm.scss';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import AppButton from './AppButton';
import { gtag } from './utils';
import { useAuth } from './AuthProvider';

function PasswordResetNextStepsForm({ closeMenu }) {

  const location = useLocation();
  const email = location.state?.email || null;
  const navigate = useNavigate();
  const { clientId } = useAuth();

  if (!email) {
    closeMenu();
    return <Navigate to="/" replace />;
  }

  const openResetPasswordPage = (event) => {
    event.preventDefault();
    gtag('event', 'resend_password_reset_link_click', {
      'unique_client_id': clientId
    });
    closeMenu();
    navigate('/request-reset-password', { state: { email: email } });
  };

  useEffect(() => {
    document.title = 'Check Your Email';
  }, []);

  return (
    <div className="auth-form-wrapper appearing-element">
      <div className="auth-form">
        <div className="auth-form-header">Check Your Email</div>
        <div className="auth-form-description">
          Please check the email address {email} for instructions to reset your password.
        </div>
        <AppButton
          onClick={openResetPasswordPage}
          requestInProgress={false}
          label="Resend email"
        />
      </div>
    </div>
  );
}

PasswordResetNextStepsForm.propTypes = {
  closeMenu: PropTypes.func
};

export default PasswordResetNextStepsForm;
