import './GoogleSignInButton.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { gtag } from './utils';
import { RequestStatus } from './constants';
import { useAuth } from './AuthProvider';
import { LoginMethod } from './LoginMethod';

function GoogleSignInButton() {

  const wrapperRef = useRef(null);
  const buttonRef = useRef(null);
  const [signInStatus, setSignInStatus] = useState(null);
  const {
    onLogin,
    googleSignInLibraryLoaded,
    clientId
  } = useAuth();
  const [signInError, setSignInError] = useState(null);

  const onGoogleSignIn = useCallback((googleResponse) => {
    setSignInStatus(RequestStatus.IN_PROGRESS);
    setSignInError(null);
    axios.post('/api/login-with-google', {
      credential: googleResponse.credential,
      clientId: clientId
    })
      .then((response) => {
        setSignInStatus(RequestStatus.SUCCESS);
        onLogin(response.data, LoginMethod.GOOGLE);
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          setSignInError({ alreadyExists: true });
          setSignInStatus(RequestStatus.SUCCESS);
        } else {
          console.error(error, 'An error occurred while signing in with Google');
          setSignInStatus(RequestStatus.ERROR);
        }
      });
  }, [setSignInStatus, setSignInError, onLogin, clientId]);

  useEffect(() => {
    if (googleSignInLibraryLoaded && clientId) {
      window.google.accounts.id.initialize({
        client_id: '891809841359-lu7s0jrnoid4kaf0sr3igsbhb5r4keb5.apps.googleusercontent.com',
        callback: onGoogleSignIn,
        same_site: 'none'
      });
      window.google.accounts.id.renderButton(buttonRef.current, {
        'width': Math.min(wrapperRef.current.offsetWidth, 400),
        'click_listener': () => {
          gtag('event', 'google_sign_in_click', {
            'unique_client_id': clientId
          });
        }
      });
    }
  }, [googleSignInLibraryLoaded, clientId]);

  return (
    <div className="login-btn-wrapper" ref={wrapperRef}>
      {signInError && signInError.alreadyExists &&
        <div className="form-error-message">
          Oops, use your original authentication method to sign in using this email.
        </div>
      }
      {signInStatus === RequestStatus.ERROR &&
        <div className="form-error-message">
          Oops, something went wrong. Please try again.
        </div>
      }
      <div ref={buttonRef} className="google-sign-in-btn" />
    </div>
  );
}

export default GoogleSignInButton;
