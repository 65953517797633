import './CheckoutSuccessPage.scss';
import { useEffect, useRef } from 'react';
import { useAuth } from './AuthProvider';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { gtag } from './utils';

function CheckoutSuccessPage({ closeMenu }) {

  const {
    authInfo,
    clientId,
    refreshUserData
  } = useAuth();
  const navigate = useNavigate();
  const hadFreePlanRef = useRef(false);

  useEffect(() => {
    if (!authInfo.hasPremiumPlan) {
      hadFreePlanRef.current = true;
      const intervalId = setInterval(() => {
        refreshUserData();
      }, 5000, 5000);
      return () => clearInterval(intervalId);
    }
  }, [authInfo.hasPremiumPlan]);

  const openGamesPage = (e) => {
    e.preventDefault();
    gtag('event', 'open_dilemmas_after_payment_click', {
      'unique_client_id': clientId
    });
    closeMenu();
    navigate('/dilemmas');
  };

  useEffect(() => {
    document.title = 'Checkout Success';
  }, []);

  return <div className="checkout-success-page appearing-element">
    <div className="checkout-success-page-title">Thank you for your purchase</div>
    <div className="subscription-status-narrative">
      You have just completed your payment.&nbsp;
      {!authInfo.hasPremiumPlan &&
        <>
          Your subscription is being provisioned and will be available soon. Please stay on this
          page or come back to our website later to access your subscription.
          <div className="rotating-spinner-container">
            <i className="fa-solid fa-spinner rotating-spinner"></i>
          </div>
        </>
      }
      {authInfo.hasPremiumPlan &&
        <div className={`active-subscription-narrative`}>
          Your subscription has been successfully activated! You can start enjoying your
          subscription benefits right away.
          <div
            className={`open-games-btn-container ${hadFreePlanRef.current && 'appearing-element'}`}
          >
            <button
              onClick={openGamesPage}
              className="app-button"
            >
              Open Games
            </button>
          </div>
        </div>
      }
    </div>
  </div>;
}

CheckoutSuccessPage.propTypes = {
  closeMenu: PropTypes.func
};

export default CheckoutSuccessPage;
