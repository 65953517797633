import AuthPasswordField from './AuthPasswordField';
import AppButton from './AppButton';
import { gtag, validatePassword } from './utils';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { executeRequestWithMinDelay } from 'common-web/src/js/helper';
import axios from 'axios';
import { RequestStatus } from './constants';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

function ChangePasswordForm({
  resetPasswordToken,
  updateTokenValidationError,
  closeMenu
}) {

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [secondaryPassword, setSecondaryPassword] = useState('');
  const [secondaryPasswordError, setSecondaryPasswordError] = useState(null);
  const [resetRequestStatus, setResetRequestStatus] = useState(null);
  const navigate = useNavigate();
  const { clientId } = useAuth();

  const handlePasswordChange = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const handleSecondaryPasswordChange = (event) => {
    event.preventDefault();
    setSecondaryPassword(event.target.value);
  };

  const validateForm = () => {
    let passwordResult = validatePassword(password);
    setPasswordError(passwordResult);

    let passwordsMatch = password === secondaryPassword;
    let secondaryPasswordError;
    if (passwordsMatch) {
      secondaryPasswordError = null;
    } else {
      secondaryPasswordError = { doNotMatch: true };
    }
    setSecondaryPasswordError(secondaryPasswordError);

    return passwordResult === null && secondaryPasswordError === null;
  };

  const openPasswordChangedForm = () => {
    closeMenu();
    navigate(`/password-changed/${resetPasswordToken}`);
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    gtag('event', 'change_password_on_reset_click', {
      'unique_client_id': clientId
    });

    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    const request = {
      password: password,
      token: resetPasswordToken
    };
    await executeRequestWithMinDelay({
      executeRequest: () => axios.post('/api/reset-password', request),
      onRequestStatusUpdate: setResetRequestStatus,
      onError: (error) => {
        gtag('event', 'failed_change_password_on_reset', {
          'unique_client_id': clientId
        });
        console.error(error, 'An error occurred while executing reset password request');
        setResetRequestStatus(RequestStatus.ERROR);
      },
      onSuccess: (response) => {
        if (response.data.error === null) {
          gtag('event', 'password_changed_on_reset', {
            'unique_client_id': clientId
          });
          openPasswordChangedForm();
        } else {
          gtag('event', 'token_check_failed_on_pass_change', {
            'unique_client_id': clientId,
            'validation_error_code': response.data.error
          });
          updateTokenValidationError(response.data.error);
        }
      }
    });
  };

  const onEnterHandlePasswordReset = (event) => {
    if (resetRequestStatus !== RequestStatus.IN_PROGRESS) {
      return handlePasswordReset(event);
    }
  };

  const handleSecondaryPasswordKeyDown = (event) => {
    if (event.key === 'Enter') {
      return onEnterHandlePasswordReset(event);
    }
  };

  return (
    <>
      <div className="auth-form-header">Reset Password</div>
      <div className="auth-form-description">
        Enter a new password below to change your password.
      </div>
      <div>
        <AuthPasswordField
          passwordError={passwordError}
          password={password}
          handlePasswordChange={handlePasswordChange}
          placeholder="New password"
          handleEnterClick={onEnterHandlePasswordReset}
        />
        <div>
          <input
            type="password"
            className={`form-input second-password-input ${secondaryPasswordError !== null ? 'invalid-input' : ''}`}
            placeholder="Re-enter new password"
            value={secondaryPassword}
            onChange={handleSecondaryPasswordChange}
            onKeyDown={handleSecondaryPasswordKeyDown}
          />
          {secondaryPasswordError && secondaryPasswordError.doNotMatch &&
            <div className="input-error-message">Passwords don{'\''}t match.</div>}
        </div>
        <div className="auth-continue-button-wrapper">
          {resetRequestStatus === RequestStatus.ERROR &&
            <div className="form-error-message">Oops, something went wrong. Please try again.</div>}
          <AppButton
            onClick={handlePasswordReset}
            requestInProgress={resetRequestStatus === RequestStatus.IN_PROGRESS}
            label="Reset password"
          />
        </div>
      </div>
    </>
  );
}

ChangePasswordForm.propTypes = {
  resetPasswordToken: PropTypes.string,
  updateTokenValidationError: PropTypes.func,
  closeMenu: PropTypes.func
};

export default ChangePasswordForm;
