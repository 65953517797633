import './MembershipInfo.scss';
import { useEffect, useState } from 'react';
import { RequestStatus } from './constants';
import axios from 'axios';
import { gtag } from './utils';
import { executeRequestWithMinDelay } from 'common-web/src/js/helper';
import { useAuth } from './AuthProvider';

function MembershipInfo() {

  const [membershipInfoLoadingStatus, setMembershipInfoLoadingStatus] = useState(null);
  const [membershipInfo, setMembershipInfo] = useState();
  const [manageRequestStatus, setManageRequestStatus] = useState(null);
  const { clientId } = useAuth();

  const loadInfo = () => {
    setMembershipInfoLoadingStatus(RequestStatus.IN_PROGRESS);
    axios.get('/api/user/membership-info')
      .then((response) => {
        setMembershipInfo(response.data);
        setMembershipInfoLoadingStatus(RequestStatus.SUCCESS);
      })
      .catch(error => {
        console.error(error, 'An error occurred while fetching membership info');
        setMembershipInfoLoadingStatus(RequestStatus.ERROR);
      });
  };

  const retryLoadInfo = () => {
    gtag('event', 'retry_load_membership_info_click', {
      'unique_client_id': clientId
    });
    loadInfo();
  };

  useEffect(() => {
    loadInfo();
  }, []);

  const manageSubscription = async (e) => {
    e.preventDefault();
    if (manageRequestStatus === RequestStatus.IN_PROGRESS) {
      return;
    }
    gtag('event', 'manage_subscription_click', {
      'unique_client_id': clientId
    });
    const request = {
      'baseUrl': window.location.origin
    };
    await executeRequestWithMinDelay({
      executeRequest: () => axios.post('/api/customer-portal/create-session', request),
      onRequestStatusUpdate: setManageRequestStatus,
      onSuccess: (response) => {
        window.location.href = response.data.url;
      }
    });
  };

  return <div className="membership-info">
    <div className="membership-header appearing-element">Manage Membership</div>
    {membershipInfoLoadingStatus === RequestStatus.IN_PROGRESS &&
      <div className="page-loading-spinner">
        <i className="fa-solid fa-spinner rotating-spinner"></i>
      </div>}
    {membershipInfoLoadingStatus === RequestStatus.SUCCESS &&
      <div className="membership-info-container appearing-element">
        <div className="membership-info-row">
          <div>Your Premium Plan</div>
          {!membershipInfo.cancelAtPeriodEnd &&
            <div className="plan-status-label">ACTIVE</div>
          }
          {membershipInfo.cancelAtPeriodEnd &&
            <div className="plan-status-label cancelling-plan-label">CANCELLING</div>
          }
        </div>
        {membershipInfo.hasSubscription &&
          <>
            {membershipInfo.hasPremiumPlan &&
              <div className="membership-info-row">
                <div>
                  {membershipInfo.cancelAtPeriodEnd ? 'Cancelling On' : 'Renewal Date'}
                </div>
                <div>{new Date(membershipInfo.currentPeriodEnd).toLocaleDateString()}</div>
              </div>
            }
            <div className="membership-info-actions">
              {manageRequestStatus === RequestStatus.ERROR &&
                <div className="form-error-message">
                  Oops, something went wrong. Please try again.
                </div>}
              <div
                onClick={manageSubscription}
                className="app-link change-plan-status-btn"
              >
                Manage my subscription
                {manageRequestStatus === RequestStatus.IN_PROGRESS &&
                  <i className="fa-solid fa-spinner rotating-spinner"></i>}
              </div>
            </div>
          </>
        }
      </div>
    }
    {membershipInfoLoadingStatus === RequestStatus.ERROR &&
      <div className="fetch-page-error">
        Oops, something went wrong. Please try again.
        <button
          className="app-button retry-btn"
          onClick={retryLoadInfo}
        >
          Retry
        </button>
      </div>}
  </div>;
}

export default MembershipInfo;
