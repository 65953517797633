import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AppButton from './AppButton';
import { gtag } from './utils';
import { useAuth } from './AuthProvider';

function PasswordChangedForm({ closeMenu }) {

  const navigate = useNavigate();
  const { clientId } = useAuth();

  const openLoginPage = (event) => {
    event.preventDefault();
    gtag('event', 'open_login_click_after_pass_change', {
      'unique_client_id': clientId
    });
    closeMenu();
    navigate('/login');
  };

  useEffect(() => {
    document.title = 'Password Changed';
  }, []);

  return (
    <div className="auth-form-wrapper appearing-element">
      <div className="auth-form">
        <div className="auth-form-header">Password Changed!</div>
        <div className="auth-form-description">
          Your password has been changed successfully.
        </div>
        <AppButton
          onClick={openLoginPage}
          requestInProgress={false}
          label="Back to Login"
        />
      </div>
    </div>
  );
}

PasswordChangedForm.propTypes = {
  closeMenu: PropTypes.func
};

export default PasswordChangedForm;
