import './Game.scss';
import { createRef, useCallback, useState } from 'react';
import SelectionScreen from './SelectionScreen';
import GameCompletionScreen from './GameCompletionScreen';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { gtag } from './utils';
import { MoralValue } from 'common-web/src/js/constants';
import { useAuth } from './AuthProvider';

const gameScreen = {
  activeScreenIndex: 0
};

const completionScreen = {
  activeScreenIndex: 1
};

function Game({
  gameId,
  openGamesPage,
  closeMenu,
  isHidden,
  setActiveGameId
}) {

  const [activeScreen, setActiveScreen] = useState(gameScreen);
  const [moralValue, setMoralValue] = useState();
  const [numberOfMurders, setNumberOfMurders] = useState();
  const {
    clientId,
    authInfo,
    canAccessPremiumGames
  } = useAuth();

  const navigate = useNavigate();

  const completeGame = useCallback((options) => {
    let numberOfMurders = 0;
    let numberOfSelflessSelections = 0;
    let numberOfSelfishSelections = 0;
    for (const option of options) {
      numberOfMurders += option.creaturesNumber;
      if (option.moralValue === MoralValue.SELFLESS) {
        numberOfSelflessSelections += 1;
      } else if (option.moralValue === MoralValue.SELFISH) {
        numberOfSelfishSelections += 1;
      }
    }
    let playerMoralValue;
    if (numberOfSelflessSelections > 0 && numberOfSelfishSelections === 0) {
      playerMoralValue = MoralValue.SELFLESS;
    } else if (numberOfSelflessSelections === 0 && numberOfSelfishSelections > 0) {
      playerMoralValue = MoralValue.SELFISH;
    } else {
      playerMoralValue = MoralValue.NEUTRAL;
    }
    gtag('event', 'game_completion', {
      'game_id': gameId,
      'unique_client_id': clientId,
      'moral_value': playerMoralValue,
      'number_of_murders': numberOfMurders
    });

    setMoralValue(playerMoralValue);
    setNumberOfMurders(numberOfMurders);
    setActiveScreen(completionScreen);
  }, [gameId, setMoralValue, setNumberOfMurders, setActiveScreen, clientId]);

  const closeMenuAndOpenGames = useCallback(() => {
    gtag('event', 'open_dilemmas_click', {
      'game_id': gameId,
      'unique_client_id': clientId
    });
    closeMenu();
    setActiveGameId(null);
    if (openGamesPage) {
      openGamesPage({ playedGameId: gameId });
    } else {
      navigate('/dilemmas', { state: { playedGameId: gameId } });
    }
  }, [gameId, closeMenu, setActiveGameId, openGamesPage, navigate, clientId]);

  const openGame = (game) => {
    setActiveGameId(null);
    closeMenu();

    if (!game.isFree && !canAccessPremiumGames(authInfo)) {
      closeMenu();
      navigate('/manage-plan');
    } else {
      if (openGamesPage) {
        openGamesPage({ gameIdToStart: game.id });
      } else {
        navigate('/dilemmas', { state: { gameIdToStart: game.id } });
      }
    }
  };

  return (<>
    {activeScreen.activeScreenIndex === 0 && <SelectionScreen
      ref={createRef()}
      key="game-screen"
      onGameCompletion={completeGame}
      isHidden={isHidden}
      gameId={gameId}
    />}
    {activeScreen.activeScreenIndex === 1 && <GameCompletionScreen
      ref={createRef()}
      key="completion-screen"
      className="active"
      openGamesPage={closeMenuAndOpenGames}
      moralValue={moralValue}
      numberOfMurders={numberOfMurders}
      gameId={gameId}
      openGame={openGame}
    />}
  </>);
}

Game.propTypes = {
  gameId: PropTypes.number,
  openGamesPage: PropTypes.func,
  closeMenu: PropTypes.func,
  isHidden: PropTypes.bool,
  setActiveGameId: PropTypes.func
};

export default Game;
