import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SCREEN_TRANSITION_SPEED } from './constants';
import PropTypes from 'prop-types';

function FadeTransitionGroup({ children }) {
  return (<TransitionGroup component={null}>
    {children
      // to allow dynamic mappings within the component
      .flatMap(child => child)
      .filter(child => !!child.key)
      .map(child => {
        return <CSSTransition
          nodeRef={child.ref}
          key={child.key}
          classNames="fade"
          timeout={SCREEN_TRANSITION_SPEED}
        >
          {child}
        </CSSTransition>;
      })}
  </TransitionGroup>);
}

FadeTransitionGroup.propTypes = {
  children: PropTypes.array
};

export default FadeTransitionGroup;
