import PropTypes from 'prop-types';

// this function is a workaround to prevent loading two images by the 'picture' element
function addSrc(src) {
  return function (img) {
    if (img && !img.src) {
      img.src = src;
    }
  };
}

function WebPicture({
  webpSrc,
  defaultSrc,
  alt,
  pictureClassName,
  imgClassName,
  imgStyle
}) {

  return (<picture className={pictureClassName}>
    <source srcSet={webpSrc} type="image/webp" />
    <img
      ref={addSrc(defaultSrc)}
      alt={alt}
      className={imgClassName}
      style={imgStyle}
      draggable={false}
    />
  </picture>);
}

WebPicture.propTypes = {
  webpSrc: PropTypes.string,
  defaultSrc: PropTypes.string,
  alt: PropTypes.string,
  pictureClassName: PropTypes.any,
  imgClassName: PropTypes.any,
  imgStyle: PropTypes.any
};

export default WebPicture;
