import 'common-web/src/sass/game-cover.scss';
import './GamesList.scss';
import PropTypes from 'prop-types';
import { RequestStatus } from './constants';
import WebPicture from './WebPicture';
import default_cover_jpeg from 'common-web/src/assets/default-cover.jpeg';
import default_cover_webp from 'common-web/src/assets/default-cover.webp';

function GamesList({
  loadingStatus,
  playedGameId,
  games,
  onGameClick,
  retryLoadGames
}) {

  return (
    <div className="collections-container">
      {loadingStatus === RequestStatus.IN_PROGRESS &&
        <div className="page-loading-spinner">
          <i className="fa-solid fa-spinner rotating-spinner"></i>
        </div>}
      {loadingStatus === RequestStatus.SUCCESS && games.map(game => {
        return <div key={game.id} className="collection appearing-element">
          <div
            onClick={() => onGameClick(game)}
            className="collection-cover-wrapper"
          >
            <div className="dynamic-cover-container">
              {playedGameId === game.id &&
                <div className="played-cover-overlay">
                  Just Played
                </div>
              }
              <WebPicture
                defaultSrc={game.cover ?
                  `/assets/images/covers/${game.cover}.jpeg` :
                  default_cover_jpeg}
                webpSrc={game.cover ?
                  `/assets/images/covers/${game.cover}.webp` :
                  default_cover_webp}
                pictureClassName="cover-img-wrapper"
                imgClassName="collection-cover"
                alt={game.title}
              />
              {!game.isFree &&
                <div className="paid-game-label">PREMIUM</div>
              }
            </div>
            <div className="collection-title">{game.title}</div>
          </div>
          <button
            className="app-button start-button"
            onClick={() => onGameClick(game)}
          >
            Start
          </button>
        </div>;
      })}
      {loadingStatus === RequestStatus.ERROR &&
        <div className="fetch-page-error">
          Oops, something went wrong. Please try again.
          <button
            className="app-button retry-btn"
            onClick={retryLoadGames}
          >
            Retry
          </button>
        </div>}
    </div>
  );
}

GamesList.propTypes = {
  loadingStatus: PropTypes.string,
  playedGameId: PropTypes.number,
  games: PropTypes.array,
  onGameClick: PropTypes.func,
  retryLoadGames: PropTypes.func
};

export default GamesList;
