import { useAuth } from './AuthProvider';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function ProtectedRoute({ children }) {

  const { authInfo } = useAuth();
  const location = useLocation();

  if (!authInfo) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children ? children : <Outlet />;
}

ProtectedRoute.propTypes = {
  children: PropTypes.any
};

export default ProtectedRoute;
