import PropTypes from 'prop-types';
import './StartScreen.scss';
import { forwardRef } from 'react';

const StartScreen = forwardRef(({
  onStartClick,
  className
}, ref) => {

  return (<div className={`start-game-page-container ${className}`} ref={ref}>
    <div className="home-page-main-content">
      <div className="game-instruction-container">
        <div className="game-instruction">
          Get ready for tough decisions! Enter the world of the trolley problem, choose your path,
          and face the consequences. Which track will you choose? Make your choice
          and advance to the next level!
        </div>
      </div>
      <div className="start-button-container">
        <button className="app-button main-start-button" onClick={onStartClick}>
          Start
        </button>
      </div>
    </div>
    <div className="home-page-footer">
      <div className="footer-links-container">
        <a href="/docs/tos.pdf" className="footer-link terms-of-service-link">Terms</a>
        <div className="separator">|</div>
        <a href="/docs/privacy.pdf" className="footer-link privacy-policy-link">Privacy</a>
      </div>
    </div>
  </div>);
});

StartScreen.displayName = 'StartScreen';

StartScreen.propTypes = {
  onStartClick: PropTypes.func,
  className: PropTypes.string
};

export default StartScreen;
