import PropTypes from 'prop-types';
import { MAXIMUM_PASSWORD_LENGTH, MINIMUM_PASSWORD_LENGTH } from './constants';

function AuthPasswordField({
  passwordError,
  password,
  handlePasswordChange,
  placeholder,
  handleEnterClick
}) {

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && handleEnterClick) {
      handleEnterClick(event);
    }
  };

  return (
    <div>
      <input
        type="password"
        className={`form-input ${passwordError !== null ? 'invalid-input' : ''}`}
        placeholder={placeholder ? placeholder : 'Password'}
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={handleKeyDown}
      />
      {passwordError && passwordError.isEmpty &&
        <div className="input-error-message">Please enter a password.</div>}
      {passwordError && passwordError.tooShort &&
        <div className="input-error-message">
          The minimum allowed length is {MINIMUM_PASSWORD_LENGTH}.
        </div>}
      {passwordError && passwordError.tooLong &&
        <div className="input-error-message">
          The maximum allowed length is {MAXIMUM_PASSWORD_LENGTH}.
        </div>}
    </div>
  );
}

AuthPasswordField.propTypes = {
  passwordError: PropTypes.object,
  password: PropTypes.string,
  handlePasswordChange: PropTypes.func,
  placeholder: PropTypes.string,
  handleEnterClick: PropTypes.func
};

export default AuthPasswordField;
