import './RequestResetPasswordForm.scss';
import { useEffect, useState } from 'react';
import AuthEmailField from './AuthEmailField';
import AppButton from './AppButton';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RequestStatus } from './constants';
import { gtag, validateEmail } from './utils';
import { executeRequestWithMinDelay } from 'common-web/src/js/helper';
import axios from 'axios';
import { useAuth } from './AuthProvider';
import useMetaDescription from './useMetaDescription';

function RequestResetPasswordForm({ closeMenu }) {

  const location = useLocation();
  const initialLoginEmail = location.state?.email || '';
  const [email, setEmail] = useState(initialLoginEmail);
  const [emailError, setEmailError] = useState(null);
  const navigate = useNavigate();
  const [resetPasswordRequestStatus, setResetPasswordRequestStatus] = useState(null);
  const { clientId } = useAuth();
  const [resetPasswordError, setResetPasswordError] = useState(null);

  const handleEmailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const openLoginPage = (event) => {
    event.preventDefault();
    gtag('event', 'open_login_from_forgot_password_click', {
      'unique_client_id': clientId
    });
    closeMenu();
    navigate('/login');
  };

  const validateForm = () => {
    let emailResult = validateEmail(email);
    setEmailError(emailResult);

    return emailResult === null;
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    gtag('event', 'request_password_reset_click', {
      'unique_client_id': clientId
    });

    setResetPasswordError(null);
    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    const request = {
      email: email,
      clientId: clientId
    };
    await executeRequestWithMinDelay({
      executeRequest: () => axios.post('/api/request-reset-password', request),
      onRequestStatusUpdate: setResetPasswordRequestStatus,
      onError: (error) => {
        gtag('event', 'failed_to_request_password_reset', {
          'unique_client_id': clientId
        });
        console.error(error, 'An error occurred while executing reset password request');
        setResetPasswordRequestStatus(RequestStatus.ERROR);
      },
      onSuccess: (response) => {
        if (response.data.error === null) {
          gtag('event', 'password_reset_requested', {
            'unique_client_id': clientId
          });
          closeMenu();
          navigate('/reset-password-next-steps', { state: { email: email } });
        } else if (response.data.error === 'USER_NOT_FOUND') {
          gtag('event', 'password_reset_req_user_not_found', {
            'unique_client_id': clientId
          });
          setResetPasswordError({ userNotFound: true });
        } else {
          gtag('event', 'password_reset_req_unknown_error_code', {
            'unique_client_id': clientId
          });
          setResetPasswordRequestStatus(RequestStatus.ERROR);
        }
      }
    });
  };

  const onEnterHandlePasswordReset = (event) => {
    if (resetPasswordRequestStatus !== RequestStatus.IN_PROGRESS) {
      return handlePasswordReset(event);
    }
  };

  useEffect(() => {
    document.title = 'Password Reset Request';
  }, []);

  useMetaDescription(
    'Forgot your password? Recover it quickly and resume your trolley problem adventures!'
  );

  return (
    <div className="auth-form-wrapper appearing-element">
      <div className="auth-form">
        <div className="auth-form-header">Reset Password</div>
        <div className="auth-form-description">
          Enter your email address and we will send you instructions to reset your password.
        </div>
        <div>
          <AuthEmailField
            emailError={emailError}
            email={email}
            handleEmailChange={handleEmailChange}
            handleEnterClick={onEnterHandlePasswordReset}
          />
          <div className="auth-continue-button-wrapper">
            {resetPasswordError && resetPasswordError.userNotFound &&
              <div className="form-error-message">
                Account with this email not found.
              </div>
            }
            {resetPasswordRequestStatus === RequestStatus.ERROR &&
              <div className="form-error-message">
                Oops, something went wrong. Please try again.
              </div>}
            <AppButton
              onClick={handlePasswordReset}
              requestInProgress={resetPasswordRequestStatus === RequestStatus.IN_PROGRESS}
            />
          </div>
          <div className="auth-form-hint">
            <a
              href="/login"
              className="auth-form-hint-link"
              onClick={openLoginPage}
            >
              Back to Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

RequestResetPasswordForm.propTypes = {
  closeMenu: PropTypes.func
};

export default RequestResetPasswordForm;
