import 'common-web/src/sass/buttons.scss';
import './forms.scss';
import './App.scss';
import './email-auth-form.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useCallback, useState } from 'react';
import Navigation from './Navigation';
import Games from './Games';
import ContactUs from './ContactUs';
import ErrorPage from './ErrorPage';
import Home from './Home';
import LoginForm from './LoginForm';
import AuthProvider from './AuthProvider';
import ManagePlan from './ManagePlan';
import ProtectedRoute from './ProtectedRoute';
import CheckoutSuccessPage from './CheckoutSuccessPage';
import SignUpForm from './SignUpForm';
import RequestResetPasswordForm from './RequestResetPasswordForm';
import PasswordResetNextStepsForm from './PasswordResetNextStepsForm';
import ResetPasswordForm from './ResetPasswordForm';
import PasswordChangedForm from './PasswordChangedForm';

function App() {

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMenuClosing, setMenuClosing] = useState(false);
  const [activeGameId, setActiveGameId] = useState(null);

  const openMenu = useCallback(() => {
    if (!isMenuClosing) {
      setMenuOpen(true);
    }
  }, [isMenuClosing, setMenuOpen]);

  const closeMenu = useCallback(() => {
    if (isMenuOpen && !isMenuClosing) {
      setMenuClosing(true);
      setTimeout(() => {
        if (isMenuOpen) {
          setMenuOpen(false);
          setMenuClosing(false);
        }
      }, 500);
    }
  }, [isMenuOpen, setMenuOpen, isMenuClosing, setMenuClosing]);

  return (<div className="trolley-problem-app">
    <BrowserRouter>
      <AuthProvider closeMenu={closeMenu}>
        <Navigation
          isMenuOpen={isMenuOpen}
          isMenuClosing={isMenuClosing}
          closeMenu={closeMenu}
          openMenu={openMenu}
          activeGameId={activeGameId}
          setActiveGameId={setActiveGameId}
        />
        <div className="app-body">
          <Routes>
            <Route
              path="/"
              element={<Home closeMenu={closeMenu} setActiveGameId={setActiveGameId} />}
            />
            <Route
              path="/dilemmas"
              element={<Games closeMenu={closeMenu} setActiveGameId={setActiveGameId} />}
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/login" element={<LoginForm closeMenu={closeMenu} />} />
            <Route path="/sign-up" element={<SignUpForm closeMenu={closeMenu} />} />
            <Route
              path="/request-reset-password"
              element={<RequestResetPasswordForm closeMenu={closeMenu} />}
            />
            <Route
              path="/reset-password-next-steps"
              element={<PasswordResetNextStepsForm closeMenu={closeMenu} />}
            />
            <Route
              path="/reset-password/:resetPasswordToken"
              element={<ResetPasswordForm closeMenu={closeMenu} />}
            />
            <Route
              path="/password-changed/:resetPasswordToken"
              element={<PasswordChangedForm closeMenu={closeMenu} />}
            />
            <Route path="*" element={<ErrorPage />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/manage-plan" element={<ManagePlan />} />
              <Route
                path="/checkout-success/:sessionId"
                element={<CheckoutSuccessPage closeMenu={closeMenu} />}
              />
            </Route>
          </Routes>
        </div>
      </AuthProvider>
    </BrowserRouter>
  </div>);
}

export default App;
