import PropTypes from 'prop-types';
import './ChoiceScreen.scss';
import blood_png from './assets/blood.png';
import blood_webp from './assets/blood.webp';
import default_background_jpeg from 'common-web/src/assets/default-background.jpeg';
import default_background_webp from 'common-web/src/assets/default-background.webp';
import default_option_png from 'common-web/src/assets/default-option.png';
import default_option_webp from 'common-web/src/assets/default-option.webp';
import { forwardRef, useCallback, useState } from 'react';
import axios from 'axios';
import WebPicture from './WebPicture';
import { gtag } from './utils';
import { useAuth } from './AuthProvider';

const ChoiceScreen = forwardRef(({
  choice,
  onOptionSelection,
  className,
  isFirstChoice
}, ref) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [resultsDisplayed, setResultsDisplayed] = useState(false);
  const [choiceLiked, setChoiceLiked] = useState(false);
  const [isSavingLike, setSavingLike] = useState(false);
  const [isLikeClicked, setLikeClicked] = useState(false);
  const { clientId } = useAuth();

  const showResults = () => {
    setResultsDisplayed(true);
  };

  const selectOption = (option) => {
    gtag('event', 'option_selection', {
      'choice_id': choice.id,
      'option_id': option.id,
      'unique_client_id': clientId
    });
    if (selectedOption === null || selectedOption.id !== option.id) {
      setSelectedOption(option);
      const request = {
        'clientType': 'web',
        'clientId': clientId,
        'choiceId': choice.id,
        'optionId': option.id
      };
      axios.post('/api/choice/select-option', request)
        .catch((error) => {
          console.error(error, 'An error occurred while saving user selection');
        });
      setTimeout(() => {
        showResults();
      }, 100);
    }
  };

  const getAnotherOption = useCallback((option) => {
    if (choice.firstOption === option) {
      return choice.secondOption;
    }
    return choice.firstOption;
  }, [choice]);

  const likeChoice = useCallback((e) => {
    e.preventDefault();
    if (isSavingLike) {
      return;
    }
    setLikeClicked(true);
    const newValue = !choiceLiked;
    setChoiceLiked(newValue);
    if (newValue) {
      gtag('event', 'like_choice_click', {
        'choice_id': choice.id,
        'unique_client_id': clientId
      });
    } else {
      gtag('event', 'unlike_choice_click', {
        'choice_id': choice.id,
        'unique_client_id': clientId
      });
    }
    const request = {
      'clientId': clientId,
      'choiceId': choice.id
    };
    setSavingLike(true);
    axios.post(`/api/choice/${newValue ? 'like' : 'unlike'}`, request)
      .catch((error) => {
        console.error(error, 'An error occurred while updating choice like');
        setChoiceLiked(!newValue);
      })
      .finally(() => {
        setSavingLike(false);
      });
  }, [setLikeClicked, isSavingLike, choiceLiked, setChoiceLiked, setSavingLike, clientId]);

  const onNextClick = useCallback(() => {
    gtag('event', 'next_choice_click', {
      'choice_id': choice.id,
      'unique_client_id': clientId
    });
    onOptionSelection(selectedOption);
  }, [choice, onOptionSelection, selectedOption, clientId]);

  return (<div
    ref={ref}
    className={`choice-container ${className} ${resultsDisplayed ? 'answered-choice' : ''}`}
  >
    <div className="options-container">
      {[choice.firstOption, choice.secondOption].map((option, idx) => <div
        key={option.id}
        className={'choice-option'}
        onClick={() => selectOption(option)}
      >
        <WebPicture
          defaultSrc={choice.background
            ? `/assets/images/backgrounds/${choice.background}.jpeg`
            : default_background_jpeg}
          webpSrc={choice.background
            ? `/assets/images/backgrounds/${choice.background}.webp`
            : default_background_webp}
          imgClassName="option-background-img"
          alt="Background"
        />
        <WebPicture
          defaultSrc={option.avatar ?
            `/assets/images/options/${option.avatar}.png` :
            default_option_png}
          webpSrc={option.avatar ?
            `/assets/images/options/${option.avatar}.webp` :
            default_option_webp}
          pictureClassName="option-img-container"
          imgClassName="option-img"
          imgStyle={{
            height: `${option.heightPercent}%`,
            bottom: `${option.bottomMarginPercent}%`,
            left: `${option.leftMarginPercent}%`
          }}
          alt={option.title}
        />
        <div className={'option-label'}>
          <div className={'label-text'}>{option.title.toUpperCase()}</div>
        </div>
        <WebPicture
          defaultSrc={blood_png}
          webpSrc={blood_webp}
          pictureClassName={'blood-img-container' + (selectedOption === option ? '' : ' hidden')}
          imgClassName="blood-img"
          alt="Blood"
        />
        {isFirstChoice && idx === 0 &&
          <div className="selection-instruction">
            Which way should trolley go?
          </div>
        }
      </div>)}
    </div>
    <div className={`sliding-up-panel ${resultsDisplayed && 'displayed-panel'}`}>
      {resultsDisplayed &&
        <div className="results-container">
          <div className="result-narrative">
            <div className={'percentage'}>{selectedOption.selectionsPercentage}%</div>
            &nbsp;of people made the same decision, while&nbsp;
            <div className="percentage">{getAnotherOption(selectedOption).selectionsPercentage}%
            </div>
            &nbsp;chose the alternative option.
          </div>
          <button
            className="app-button next-button"
            onClick={onNextClick}
          >
            Next -&gt;
          </button>
        </div>}
      <div
        className={`like-choice-wrapper ${isLikeClicked && 'like-clicked'}`}
        onClick={likeChoice}
      >
        {!choiceLiked &&
          <i className="fa-regular fa-heart"></i>
        }
        {choiceLiked &&
          <i className="fa-solid fa-heart"></i>
        }
        <div className="like-label">
          Like
        </div>
      </div>
    </div>
  </div>);
});

ChoiceScreen.displayName = 'ChoiceScreen';

ChoiceScreen.propTypes = {
  choice: PropTypes.object,
  onOptionSelection: PropTypes.func,
  className: PropTypes.any,
  isFirstChoice: PropTypes.bool
};

export default ChoiceScreen;
