import './SignUpForm.scss';
import { useAuth } from './AuthProvider';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import GoogleSignInButton from './GoogleSignInButton';
import FormSeparator from './FormSeparator';
import EmailSignUpForm from './EmailSignUpForm';
import PropTypes from 'prop-types';
import useMetaDescription from './useMetaDescription';

function SignUpForm({ closeMenu }) {

  const { authInfo } = useAuth();
  const location = useLocation();

  if (authInfo) {
    let origin;
    if (location.state?.from?.pathname) {
      origin = location.state?.from?.pathname;
    } else if (!authInfo.hasPremiumPlan) {
      origin = '/manage-plan';
    } else {
      origin = '/dilemmas';
    }
    return <Navigate to={origin} replace />;
  }

  useEffect(() => {
    document.title = 'Sign Up';
  }, []);

  useMetaDescription(
    'Can you make the right choice? Sign up for trolleyproblem.io and find out with our interactive ethical game!'
  );

  return (
    <div className="auth-form-wrapper appearing-element">
      <div className="auth-form">
        <div className="auth-form-header">Create Account</div>
        <GoogleSignInButton />
        <FormSeparator />
        <EmailSignUpForm closeMenu={closeMenu} />
      </div>
    </div>
  );
}

SignUpForm.propTypes = {
  closeMenu: PropTypes.func
};

export default SignUpForm;
