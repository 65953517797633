import PropTypes from 'prop-types';

function AuthEmailField({
  emailError,
  email,
  handleEmailChange,
  handleEnterClick
}) {

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && handleEnterClick) {
      handleEnterClick(event);
    }
  };

  return (
    <div>
      <input
        type="email"
        className={`form-input ${emailError !== null ? 'invalid-input' : ''}`}
        placeholder="Email"
        value={email}
        onChange={handleEmailChange}
        onKeyDown={handleKeyDown}
      />
      {emailError && <div className="input-error-message">Please enter a valid email.</div>}
    </div>
  );
}

AuthEmailField.propTypes = {
  emailError: PropTypes.object,
  email: PropTypes.string,
  handleEmailChange: PropTypes.func,
  handleEnterClick: PropTypes.func
};

export default AuthEmailField;
