import './Games.scss';
import { useCallback, useEffect, useState } from 'react';
import Game from './Game';
import { RequestStatus } from './constants';
import axios from 'axios';
import PropTypes from 'prop-types';
import { gtag } from './utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import useMetaDescription from './useMetaDescription';
import GamesList from './GamesList';

function Games({
  closeMenu,
  setActiveGameId
}) {

  const [gamesLoadingStatus, setGamesLoadingStatus] = useState(null);
  const [games, setGames] = useState();
  const [startedGame, setStartedGame] = useState(null);
  const location = useLocation();
  const [playedGameId, setPlayedGameId] = useState(null);
  const {
    authInfo,
    clientId,
    canAccessPremiumGames
  } = useAuth();
  const navigate = useNavigate();
  const [gameIdToStart, setGameIdToStart] = useState(null);

  const startGame = useCallback((game) => {
    gtag('event', 'start_game_click', {
      'game_id': game.id,
      'unique_client_id': clientId
    });
    if (!game.isFree && !canAccessPremiumGames(authInfo)) {
      closeMenu();
      navigate('/manage-plan');
    } else {
      setStartedGame(game);
    }
  }, [canAccessPremiumGames, closeMenu, navigate, setStartedGame, clientId, authInfo]);

  const openGames = useCallback(({
    playedGameId,
    gameIdToStart
  }) => {
    setPlayedGameId(playedGameId ? playedGameId : null);
    if (gameIdToStart) {
      const game = games.find(game => game.id === gameIdToStart);
      setStartedGame(game);
    } else {
      setStartedGame(null);
    }
  }, [setStartedGame, setPlayedGameId, games]);

  const loadGames = useCallback(() => {
    setGamesLoadingStatus(RequestStatus.IN_PROGRESS);
    axios.get('/api/game/list')
      .then((gamesResponse) => {
        setGames(gamesResponse.data);
        setGamesLoadingStatus(RequestStatus.SUCCESS);
      })
      .catch(error => {
        console.error(error, 'An error occurred while fetching games');
        setGamesLoadingStatus(RequestStatus.ERROR);
      });
  }, [setGames, setGamesLoadingStatus]);

  const retryLoadGames = useCallback(() => {
    gtag('event', 'retry_load_dilemmas_click', {
      'unique_client_id': clientId
    });
    loadGames();
  }, [loadGames, clientId]);

  useEffect(() => {
    loadGames();
  }, [loadGames]);

  useEffect(() => {
    document.title = 'Dilemmas';
  }, []);

  useMetaDescription(
    'Unravel diverse trolley problems: Popular, Villains, Famous Faces. Are you ready to make life-altering choices?'
  );

  useEffect(() => {
    if (location.state) {
      let anyAttributeWasPresent = false;
      if (location.state.playedGameId) {
        setPlayedGameId(Number(location.state.playedGameId));
        anyAttributeWasPresent = true;
      }

      if (location.state.gameIdToStart) {
        setGameIdToStart(Number(location.state.gameIdToStart));
        anyAttributeWasPresent = true;
      }

      if (anyAttributeWasPresent) {
        // clear the location state
        window.history.replaceState(null, '', window.location.pathname);
      }
    }
  }, [location, setPlayedGameId, setGameIdToStart]);

  useEffect(() => {
    if (gameIdToStart && games && games.length > 0) {
      setGameIdToStart(null);
      const game = games.find(game => game.id === gameIdToStart);
      setStartedGame(game);
    }
  }, [gameIdToStart, setGameIdToStart, games, setStartedGame]);

  return <>
    {startedGame === null && gameIdToStart === null && <div className="games-page">
      <div className="games-page-heading appearing-element">
        <div className="main-heading">Trolley Problems</div>
        <div className="description-heading">Which track will you choose?</div>
      </div>
      <GamesList
        loadingStatus={gamesLoadingStatus}
        playedGameId={playedGameId}
        games={games}
        onGameClick={startGame}
        retryLoadGames={retryLoadGames}
      />
    </div>}
    {startedGame === null && gameIdToStart !== null && <>
      {gamesLoadingStatus === RequestStatus.IN_PROGRESS &&
        <div className="page-loading-spinner">
          <i className="fa-solid fa-spinner rotating-spinner"></i>
        </div>}
      {gamesLoadingStatus === RequestStatus.ERROR &&
        <div className="fetch-page-error">
          Oops, something went wrong. Please try again.
          <button
            className="app-button retry-btn"
            onClick={retryLoadGames}
          >
            Retry
          </button>
        </div>}
    </>}
    {startedGame !== null && <Game
      key={startedGame.id}
      gameId={startedGame.id}
      openGamesPage={openGames}
      closeMenu={closeMenu}
      setActiveGameId={setActiveGameId}
    />}
  </>;
}

Games.propTypes = {
  closeMenu: PropTypes.func,
  setActiveGameId: PropTypes.func
};

export default Games;
