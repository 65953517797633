import PropTypes from 'prop-types';
import { useState } from 'react';
import { gtag, validateEmail, validatePassword } from './utils';
import AuthEmailField from './AuthEmailField';
import AuthPasswordField from './AuthPasswordField';
import { useNavigate } from 'react-router-dom';
import AppButton from './AppButton';
import { RequestStatus } from './constants';
import { executeRequestWithMinDelay } from 'common-web/src/js/helper';
import axios from 'axios';
import { useAuth } from './AuthProvider';

function EmailSignUpForm({ closeMenu }) {

  const [signUpError, setSignUpError] = useState(null);
  const [requestStatus, setRequestStatus] = useState(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [showFullForm, setShowFullForm] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const navigate = useNavigate();
  const {
    clientId,
    onLogin
  } = useAuth();

  const continueWithEnteredEmail = (event) => {
    event.preventDefault();

    gtag('event', 'continue_sign_up_to_password_click', {
      'unique_client_id': clientId
    });

    let emailResult = validateEmail(email);
    setEmailError(emailResult);

    if (emailResult === null) {
      setShowFullForm(true);
    }
  };

  const handleEmailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const validateForm = () => {
    let emailResult = validateEmail(email);
    setEmailError(emailResult);

    let passwordResult = validatePassword(password);
    setPasswordError(passwordResult);

    return emailResult === null && passwordResult === null;
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    gtag('event', 'sign_up_with_email_click', {
      'unique_client_id': clientId
    });

    setSignUpError(null);
    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    const request = {
      email: email,
      password: password,
      clientId: clientId
    };
    await executeRequestWithMinDelay({
      executeRequest: () => axios.post('/api/sign-up', request),
      onRequestStatusUpdate: setRequestStatus,
      onError: (error) => {
        gtag('event', 'failed_sign_up_with_email', {
          'unique_client_id': clientId
        });
        console.error(error, 'An error occurred while executing sign-up request');
        setRequestStatus(RequestStatus.ERROR);
      },
      onSuccess: (response) => {
        if (response.data.error === null) {
          gtag('event', 'successful_sign_up_with_email', {
            'unique_client_id': clientId
          });
          onLogin(response.data.loginResult, 'EMAIL');
        } else if (response.data.error === 'ALREADY_EXISTS') {
          gtag('event', 'sign_up_error_email_exists', {
            'unique_client_id': clientId
          });
          setSignUpError({ alreadyExists: true });
        } else {
          gtag('event', 'sign_up_unknown_error_code', {
            'unique_client_id': clientId
          });
          setRequestStatus(RequestStatus.ERROR);
        }
      }
    });
  };

  const openLoginPage = (event) => {
    event.preventDefault();
    gtag('event', 'open_login_from_sign_up_click', {
      'unique_client_id': clientId
    });
    closeMenu();
    navigate('/login');
  };

  const continueToTheNextStep = (event) => {
    if (requestStatus !== RequestStatus.IN_PROGRESS) {
      if (!showFullForm) {
        return continueWithEnteredEmail(event);
      } else {
        return handleSignUp(event);
      }
    }
  };

  return (
    <div className="email-auth-form">
      <AuthEmailField
        emailError={emailError}
        email={email}
        handleEmailChange={handleEmailChange}
        handleEnterClick={continueToTheNextStep}
      />
      {showFullForm &&
        <div className="supplementary-email-auth-form-part">
          <AuthPasswordField
            passwordError={passwordError}
            password={password}
            handlePasswordChange={handlePasswordChange}
            handleEnterClick={continueToTheNextStep}
          />
        </div>
      }
      <div className="auth-continue-button-wrapper">
        {signUpError && signUpError.alreadyExists &&
          <div className="form-error-message">
            Account with this email exists.
          </div>
        }
        {requestStatus === RequestStatus.ERROR &&
          <div className="form-error-message">Oops, something went wrong. Please try again.</div>}
        <AppButton
          onClick={continueToTheNextStep}
          requestInProgress={requestStatus === RequestStatus.IN_PROGRESS}
        />
      </div>
      <div className="auth-form-hint">
        <div className="auth-form-hint-question">Already have an account?</div>
        &nbsp;
        <a
          href="/login"
          className="auth-form-hint-link"
          onClick={openLoginPage}
        >
          Login
        </a>
      </div>
    </div>
  );
}

EmailSignUpForm.propTypes = {
  closeMenu: PropTypes.func
};

export default EmailSignUpForm;
