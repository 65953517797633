import { useEffect } from 'react';

const useMetaDescription = (description) => {
  useEffect(() => {
    const metaDescriptionElement = document.getElementById('meta-description');

    const originalValue = metaDescriptionElement.getAttribute('content');
    metaDescriptionElement.setAttribute('content', description);
    return () => {
      metaDescriptionElement.setAttribute('content', originalValue);
    };
  }, [description]);
};

export default useMetaDescription;