import './GameCompletionScreen.scss';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import trolley_jpeg from './assets/trolley.jpeg';
import trolley_webp from './assets/trolley.webp';
import WebPicture from './WebPicture';
import { MoralValue } from 'common-web/src/js/constants';
import GameRatingSelector from './GameRatingSelector';
import RecommendedGames from './RecommendedGames';
import TwitterShareButton from './TwitterShareButton';
import { gtag } from './utils';
import { useAuth } from './AuthProvider';

const GameCompletionScreen = forwardRef(({
  className,
  openGamesPage,
  moralValue,
  numberOfMurders,
  gameId,
  openGame
}, ref) => {

  const { clientId } = useAuth();

  const handleTweet = () => {
    gtag('event', 'tweet_on_completion_click', {
      'game_id': gameId,
      'unique_client_id': clientId
    });
  };

  return (<div
    ref={ref}
    className={`${className} game-completion-screen`}>
    <div className="game-completion-summary-container appearing-element">
      <WebPicture
        defaultSrc={trolley_jpeg}
        webpSrc={trolley_webp}
        imgClassName="game-completion-img"
        alt="Trolley"
      />
      <div className="game-completion-summary">
        {moralValue === MoralValue.SELFLESS &&
          <>
            Congratulations, selfless hero! You&apos;ve reached the end of your journey with a total
            of
            &nbsp;
            <div className="murders-number">{numberOfMurders} lives lost.</div>
            &nbsp;
            Your altruistic decisions have saved the day! You&apos;re an inspiration to us all!
          </>
        }
        {moralValue === MoralValue.SELFISH &&
          <>
            Congratulations, agent of chaos! You&apos;ve reached the end of your journey with a
            total
            of&nbsp;
            <div className="murders-number">{numberOfMurders} lives lost.</div>
            &nbsp;
            While your decisions may have been selfish, we admire your ability to put your own needs
            first.
          </>
        }
        {moralValue === MoralValue.NEUTRAL &&
          <>
            Congratulations, master of balance! You&apos;ve reached the end of your journey with a
            total of&nbsp;
            <div className="murders-number">{numberOfMurders} lives lost.</div>
            &nbsp;
            You showed that it&apos;s possible to balance your own needs with the needs of others.
          </>
        }
      </div>
      <GameRatingSelector gameId={gameId} />
      <div className="completion-buttons-container">
        <button
          className="app-button open-games-btn"
          onClick={openGamesPage}
        >
          More Dilemmas
        </button>
        <TwitterShareButton
          tweetText={
            `Just tried trolleyproblem.io and lost ${numberOfMurders} lives. 🚋 Oops! How many ` +
            'can you save? Give it a go! #TrolleyProblem'
          }
          onClick={handleTweet}
        />
      </div>
    </div>
    <RecommendedGames
      currentGameId={gameId}
      openGame={openGame}
    />
  </div>);
});

GameCompletionScreen.displayName = 'GameCompletionScreen';

GameCompletionScreen.propTypes = {
  className: PropTypes.string,
  openGamesPage: PropTypes.func,
  moralValue: PropTypes.string,
  numberOfMurders: PropTypes.number,
  gameId: PropTypes.number,
  openGame: PropTypes.func
};

export default GameCompletionScreen;
