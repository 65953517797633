import PropTypes from 'prop-types';
import { DEFAULT_GAME_ID } from './constants';
import Game from './Game';
import { useCallback, useEffect, useState } from 'react';
import StartScreen from './StartScreen';
import { gtag } from './utils';
import { useAuth } from './AuthProvider';

function Home({
  closeMenu,
  setActiveGameId
}) {

  const [gameStarted, setGameStarted] = useState(false);
  const { clientId } = useAuth();

  const startGame = useCallback(() => {
    gtag('event', 'start_game_click', {
      'game_id': 'default_game',
      'unique_client_id': clientId
    });
    closeMenu();
    setActiveGameId(DEFAULT_GAME_ID);
    setGameStarted(true);
  }, [closeMenu, setGameStarted, clientId]);

  useEffect(() => {
    document.title = 'Trolley Problem Game';
  }, []);

  return (
    <>
      {!gameStarted && <StartScreen
        key="start-screen"
        onStartClick={startGame}
        className={'active'}
      />}
      <Game
        gameId={DEFAULT_GAME_ID}
        closeMenu={closeMenu}
        isHidden={!gameStarted}
        setActiveGameId={setActiveGameId}
      />
    </>);
}

Home.propTypes = {
  closeMenu: PropTypes.func,
  setActiveGameId: PropTypes.func
};

export default Home;
