import './TwitterShareButton.scss';
import PropTypes from 'prop-types';

function TwitterShareButton({ tweetText, onClick }) {

  const encodedText = encodeURIComponent(tweetText);

  const shareUrl = `https://twitter.com/share?text=${encodedText}`;

  const handleTweet = (e) => {
    e.preventDefault();
    onClick();
    window.open(shareUrl, '_blank');
  };

  return (
    <>
      <button
        className="app-button tweet-button"
        onClick={handleTweet}
      >
        <i className="fa-brands fa-twitter"></i>
        Tweet
      </button>
    </>
  );
}

TwitterShareButton.propTypes = {
  tweetText: PropTypes.string,
  onClick: PropTypes.func
};

export default TwitterShareButton;
