import './ContactUs.scss';
import { useCallback, useEffect, useState } from 'react';
import { gtag, validateEmail } from './utils';
import axios from 'axios';
import { RequestStatus } from './constants';
import { executeRequestWithMinDelay } from 'common-web/src/js/helper';
import { useAuth } from './AuthProvider';
import useMetaDescription from './useMetaDescription';

const MAXIMUM_NAME_LENGTH = 100;
const MAXIMUM_MESSAGE_LENGTH = 2000;

function ContactUs() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const { clientId } = useAuth();

  const validateForm = useCallback(() => {
    let nameResult = null;
    if (name.length > MAXIMUM_NAME_LENGTH) {
      nameResult = { tooLong: true };
    }
    setNameError(nameResult);

    let emailResult = validateEmail(email);
    setEmailError(emailResult);

    let messageResult = null;
    if (message.length === 0) {
      messageResult = { isEmpty: true };
    }
    if (message.length > MAXIMUM_MESSAGE_LENGTH) {
      messageResult = { tooLong: true };
    }
    setMessageError(messageResult);

    return nameResult === null && emailResult === null && messageResult === null;
  }, [name, setNameError, email, setEmailError, message, setMessageError]);

  const submitContactForm = useCallback(async () => {
    const isValid = validateForm();
    gtag('event', 'contact_us_form_submission', {
      'is_valid': isValid,
      'unique_client_id': clientId
    });
    if (!isValid) {
      setSubmissionStatus(null);
      return;
    }

    const request = {
      name: name,
      email: email,
      message: message
    };
    await executeRequestWithMinDelay({
      executeRequest: () => axios.post('/api/contact-us', request),
      onRequestStatusUpdate: setSubmissionStatus,
      onSuccess: () => {
        setName('');
        setEmail('');
        setMessage('');
      }
    });
  }, [
    validateForm,
    setName,
    setEmail,
    setMessage,
    setSubmissionStatus,
    name,
    email,
    message,
    clientId
  ]);

  const onEnterSubmitContactForm = (event) => {
    if (event.key === 'Enter') {
      if (submissionStatus !== RequestStatus.IN_PROGRESS) {
        return submitContactForm();
      }
    }
  };

  useEffect(() => {
    document.title = 'Contact Us';
  }, []);

  useMetaDescription(
    'Contact trolleyproblem.io team - send your feedback, suggestions, and inquiries to our team.'
  );

  return <div className="submission-form-page appearing-element">
    <div className="form-header">Get In Touch</div>
    <div className="form-description">
      If you have any questions or need help, please fill out the form below.
    </div>
    <div className="submission-form-container">
      <div>
        <input
          type="text"
          className={`form-input ${nameError !== null ? 'invalid-input' : ''}`}
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={onEnterSubmitContactForm}
        />
        {nameError && <div className="input-error-message">Please enter a valid name.</div>}
      </div>
      <div>
        <input
          type="email"
          className={`form-input ${emailError !== null ? 'invalid-input' : ''}`}
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={onEnterSubmitContactForm}
        />
        {emailError && <div className="input-error-message">Please enter a valid email.</div>}
      </div>
      <div>
        <textarea
          className={`form-input ${messageError !== null ? 'invalid-input' : ''}`}
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        {messageError && messageError.isEmpty &&
          <div className="input-error-message">Please enter a message.</div>}
        {messageError && messageError.tooLong && <div className="input-error-message">
          The maximum allowed length is {MAXIMUM_MESSAGE_LENGTH}.
        </div>}
      </div>
      {submissionStatus === RequestStatus.SUCCESS &&
        <div className="form-success-message">Thanks for contacting us!</div>}
      {submissionStatus === RequestStatus.ERROR &&
        <div className="form-error-message">Oops, something went wrong. Please try again.</div>}
      <button
        type="submit"
        className="app-button form-submission-button"
        onClick={submitContactForm}
        disabled={submissionStatus === RequestStatus.IN_PROGRESS}
      >
        <div className="form-submission-button-label">
          Submit
          {submissionStatus === RequestStatus.IN_PROGRESS &&
            <i className="fa-solid fa-spinner loading-spinner"></i>}
        </div>
      </button>
    </div>
  </div>;
}

export default ContactUs;
