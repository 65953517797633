import './LoginForm.scss';
import GoogleSignInButton from './GoogleSignInButton';
import { useAuth } from './AuthProvider';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import EmailLoginForm from './EmailLoginForm';
import PropTypes from 'prop-types';
import FormSeparator from './FormSeparator';
import useMetaDescription from './useMetaDescription';

function LoginForm({ closeMenu }) {

  const { authInfo } = useAuth();
  const location = useLocation();

  if (authInfo) {
    const origin = location.state?.from?.pathname || '/dilemmas';
    return <Navigate to={origin} replace />;
  }

  useEffect(() => {
    document.title = 'Login';
  }, []);

  useMetaDescription(
    'Sign in to trolleyproblem.io and start making tough ethical choices in our unique online trolley problem game.'
  );

  return (
    <div className="auth-form-wrapper appearing-element">
      <div className="auth-form">
        <div className="auth-form-header">Login</div>
        <GoogleSignInButton />
        <FormSeparator />
        <EmailLoginForm closeMenu={closeMenu} />
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  closeMenu: PropTypes.func
};

export default LoginForm;
