import './ResetPasswordForm.scss';
import { useEffect, useState } from 'react';
import { RequestStatus } from './constants';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ChangePasswordForm from './ChangePasswordForm';
import PropTypes from 'prop-types';
import { gtag } from './utils';
import { useAuth } from './AuthProvider';

function ResetPasswordForm({ closeMenu }) {

  const [validationRequestStatus, setValidationRequestStatus] = useState(RequestStatus.IN_PROGRESS);
  const { resetPasswordToken } = useParams();
  const [tokenValidationError, setTokenValidationError] = useState(null);
  const { clientId } = useAuth();

  const validateResetPasswordToken = () => {
    setTokenValidationError(null);
    setValidationRequestStatus(RequestStatus.IN_PROGRESS);
    const request = {
      token: resetPasswordToken
    };
    axios.post('/api/validate-reset-password-token', request)
      .then((response) => {
        setValidationRequestStatus(RequestStatus.SUCCESS);
        setTokenValidationError(response.data.error);
        if (response.data.error !== null) {
          gtag('event', 'reset_pass_token_validation_failed', {
            'unique_client_id': clientId,
            'validation_error_code': response.data.error
          });
        }
      })
      .catch(error => {
        console.error(error, 'An error occurred while validating reset password token');
        setValidationRequestStatus(RequestStatus.ERROR);
      });
  };

  const retryValidateToken = (event) => {
    event.preventDefault();
    gtag('event', 'retry_pass_token_validation_click', {
      'unique_client_id': clientId
    });

    validateResetPasswordToken();
  };

  useEffect(() => {
    validateResetPasswordToken();
  }, []);

  useEffect(() => {
    document.title = 'Reset Password';
  }, []);

  const resetPasswordInstruction = <div className="auth-form-description">
    To reset your password, return to the login page and select {'"'}Forgot
    password?{'"'} to send a new email.
  </div>;

  return (
    <div className="auth-form-wrapper appearing-element">
      <div className="auth-form">
        {validationRequestStatus === RequestStatus.IN_PROGRESS &&
          <div className="page-loading-spinner">
            <i className="fa-solid fa-spinner rotating-spinner"></i>
          </div>}
        {validationRequestStatus === RequestStatus.SUCCESS && <>
          {tokenValidationError === 'NOT_FOUND' && <>
            <div className="auth-form-header">Invalid Link</div>
            {resetPasswordInstruction}
          </>}
          {tokenValidationError === 'EXPIRED' && <>
            <div className="auth-form-header">Link Expired</div>
            {resetPasswordInstruction}
          </>}
          {tokenValidationError === 'WAS_USED' && <>
            <div className="auth-form-header">Invalid Link</div>
            <div className="auth-form-description">
              This link has already been used. To reset your password, return to the login page and
              select {'"'}Forgot password?{'"'} to send a new email.
            </div>
          </>}
          {tokenValidationError === null &&
            <ChangePasswordForm
              resetPasswordToken={resetPasswordToken}
              updateTokenValidationError={setTokenValidationError}
              closeMenu={closeMenu}
            />
          }
        </>}
        {validationRequestStatus === RequestStatus.ERROR &&
          <div className="fetch-page-error">
            Oops, something went wrong. Please try again.
            <button
              className="app-button retry-btn"
              onClick={retryValidateToken}
            >
              Retry
            </button>
          </div>}
      </div>
    </div>
  );
}

ResetPasswordForm.propTypes = {
  closeMenu: PropTypes.func
};

export default ResetPasswordForm;
