export const SCREEN_TRANSITION_SPEED = 500;

export const MAXIMUM_EMAIL_LENGTH = 250;

export const RequestStatus = {
  SUCCESS: 'success',
  ERROR: 'error',
  IN_PROGRESS: 'in_progress'
};

// The value that should be used to indicate that the default game should be loaded.
export const DEFAULT_GAME_ID = 1;

export const CLIENT_ID_PROPERTY_KEY = 'clientId';

export const MINIMUM_PASSWORD_LENGTH = 5;
export const MAXIMUM_PASSWORD_LENGTH = 50;
