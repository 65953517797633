import './RecommendedGames.scss';
import { RequestStatus } from './constants';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { gtag } from './utils';
import { useAuth } from './AuthProvider';
import PropTypes from 'prop-types';
import GamesList from './GamesList';

function RecommendedGames({
  currentGameId,
  openGame
}) {

  const [loadingStatus, setLoadingStatus] = useState(RequestStatus.IN_PROGRESS);
  const { clientId } = useAuth();
  const [recommendedGames, setRecommendedGames] = useState([]);

  const loadRecommendedGames = () => {
    setLoadingStatus(RequestStatus.IN_PROGRESS);
    axios.get(`/api/game/list-recommended?currentGameId=${currentGameId}`)
      .then((response) => {
        setRecommendedGames(response.data);
        setLoadingStatus(RequestStatus.SUCCESS);
      })
      .catch(error => {
        console.error(error, 'An error occurred while fetching recommended games');
        setLoadingStatus(RequestStatus.ERROR);
      });
  };

  const retryLoadRecommendedGames = (event) => {
    event.preventDefault();
    gtag('event', 'retry_load_recommended_games_click', {
      'unique_client_id': clientId
    });

    loadRecommendedGames();
  };

  useEffect(() => {
    loadRecommendedGames();
  }, []);

  const openRecommendedGame = (game) => {
    gtag('event', 'start_recommended_game_click', {
      'game_id': game.id,
      'unique_client_id': clientId
    });
    openGame(game);
  };

  return (
    <div className="recommended-games-container">
      <GamesList
        loadingStatus={loadingStatus}
        games={recommendedGames}
        onGameClick={openRecommendedGame}
        retryLoadGames={retryLoadRecommendedGames}
      />
    </div>
  );
}

RecommendedGames.propTypes = {
  currentGameId: PropTypes.number,
  openGame: PropTypes.func
};

export default RecommendedGames;
