import './ChoiceScreen.scss';
import ChoiceScreen from './ChoiceScreen';
import { createRef, forwardRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './SelectionScreen.scss';
import FadeTransitionGroup from './FadeTransitionGroup';
import { RequestStatus } from './constants';
import axios from 'axios';
import { gtag } from './utils';
import { useAuth } from './AuthProvider';

const SelectionScreen = forwardRef(({
  onGameCompletion,
  isHidden,
  gameId
}, ref) => {

  const [gameLoadingStatus, setGameLoadingStatus] = useState(null);
  const [choices, setChoices] = useState();
  const [displayedChoiceIndex, setDisplayedChoiceIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { clientId } = useAuth();

  const moveToTheNextChoice = (selectedOption) => {
    const newSelectedOptions = selectedOptions.concat(selectedOption);
    setSelectedOptions(newSelectedOptions);
    if (displayedChoiceIndex + 1 < choices.length) {
      setDisplayedChoiceIndex(displayedChoiceIndex + 1);
    } else {
      onGameCompletion(newSelectedOptions);
    }
  };

  const shouldChoiceBeRendered = choice => {
    let choiceIndex;
    for (let i = 0; i < choices.length; i++) {
      if (choices[i].id === choice.id) {
        choiceIndex = i;
        break;
      }
    }
    const isActiveChoice = displayedChoiceIndex === choiceIndex;
    const isNextChoice = displayedChoiceIndex + 1 === choiceIndex;
    return isActiveChoice || !isHidden && isNextChoice;
  };

  const loadChoices = useCallback(() => {
    setGameLoadingStatus(RequestStatus.IN_PROGRESS);
    const fetchPromise = axios.get(`/api/game/${gameId}/execution-info?clientType=web`);
    fetchPromise.then((response) => {
      const fetchedChoices = response.data.choices
        .map(choice => {
          if (choice.background) {
            return choice;
          }
          return {
            ...choice,
            background: response.data.background
          };
        });
      setChoices(fetchedChoices);
      setGameLoadingStatus(RequestStatus.SUCCESS);
    })
      .catch(error => {
        console.error(error, 'An error occurred while fetching game choices');
        setGameLoadingStatus(RequestStatus.ERROR);
      });
  }, [gameId, setChoices, setGameLoadingStatus]);

  const retryLoadChoices = useCallback(() => {
    gtag('event', 'retry_load_choices_click', {
      'game_id': gameId,
      'unique_client_id': clientId
    });
    loadChoices();
  }, [gameId, loadChoices, clientId]);

  useEffect(() => {
    loadChoices();
  }, [loadChoices]);

  return (<div ref={ref} className={`choices-container ${isHidden ? 'hidden' : ''}`}>
    {gameLoadingStatus === RequestStatus.IN_PROGRESS &&
      <div className="page-loading-spinner">
        <i className="fa-solid fa-spinner rotating-spinner"></i>
      </div>}
    {gameLoadingStatus === RequestStatus.SUCCESS &&
      <FadeTransitionGroup>
        {choices.filter(choice => shouldChoiceBeRendered(choice))
          .map(choice => <ChoiceScreen
            ref={createRef()}
            key={choice.id}
            choice={choice}
            onOptionSelection={moveToTheNextChoice}
            className={
              choice.id !== choices[displayedChoiceIndex].id ?
                'hidden' :
                (choice.id === choices[0].id && 'appearing-element')
            }
            isFirstChoice={choice.id === choices[0].id}
          />)}
      </FadeTransitionGroup>}
    {gameLoadingStatus === RequestStatus.ERROR &&
      <div className="fetch-page-error appearing-element">
        Oops, something went wrong. Please try again.
        <button
          className="app-button retry-btn"
          onClick={retryLoadChoices}
        >
          Retry
        </button>
      </div>}
  </div>);
});

SelectionScreen.displayName = 'SelectionScreen';

SelectionScreen.propTypes = {
  onGameCompletion: PropTypes.func,
  isHidden: PropTypes.bool,
  gameId: PropTypes.number
};

export default SelectionScreen;
