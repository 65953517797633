import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useLocalStorageState from 'use-local-storage-state';
import useScript from './useScript';
import { CLIENT_ID_PROPERTY_KEY } from './constants';
import { v4 as uuidv4 } from 'uuid';
import { gtag } from './utils';

const AuthContext = createContext(null);

const AuthProvider = ({
  children,
  closeMenu
}) => {

  const [authInfo, setAuthInfo] = useLocalStorageState('auth_info');
  let originalClientId = null;
  try {
    originalClientId = localStorage.getItem(CLIENT_ID_PROPERTY_KEY);
  } catch (error) {
    console.error('Unable to fetch clientId property from local storage', error);
    gtag('event', 'local_storage_error', {
      'event_category': 'error',
      'event_label': 'Unable to fetch clientId property from local storage',
      'error_message': error.message
    });
  }
  const [clientId, setClientId] = useState(originalClientId);
  const navigate = useNavigate();
  const [googleSignInLibraryLoaded, setGoogleSignInLibraryLoaded] = useState(false);

  const handleLogin = useCallback((authInfoResult, loginMethod) => {
    setAuthInfo({
      ...authInfoResult,
      loginMethod
    });
  }, [setAuthInfo]);

  const handleLogout = useCallback(() => {
    setAuthInfo(null);
    closeMenu();
    navigate('/login');
  }, [setAuthInfo, closeMenu, navigate]);

  const setAuthorizationHeader = useCallback((config) => {
    if (authInfo && authInfo.token) {
      config.headers.Authorization = `Bearer ${authInfo.token}`;
    }
  }, [authInfo]);

  useEffect(() => {
    if (!clientId) {
      const newClientId = uuidv4();
      setClientId(newClientId);
      try {
        localStorage.setItem(CLIENT_ID_PROPERTY_KEY, newClientId);
      } catch (error) {
        console.error('Unable to add clientId property to local storage', error);
        gtag('event', 'local_storage_error', {
          'event_category': 'error',
          'event_label': 'Unable to add clientId property to local storage',
          'error_message': error.message
        });
      }
    }
  }, [clientId, setClientId]);

  useLayoutEffect(() => {
    const interceptor = axios.interceptors.request.use(config => {
      setAuthorizationHeader(config);
      return config;
    });
    return () => axios.interceptors.request.eject(interceptor);
  }, [setAuthorizationHeader]);

  useLayoutEffect(() => {
    const interceptor = axios.interceptors.response.use(response => {
      return response;
    }, error => {
      const { status } = error.response;
      if (status === 401) {
        setAuthInfo(null);
        navigate('/login');
      }
      return Promise.reject(error);
    });
    return () => axios.interceptors.response.eject(interceptor);
  }, [setAuthInfo, navigate]);

  const refreshUserData = useCallback(() => {
    axios.get('/api/user/user-info')
      .then((response) => {
        setAuthInfo(prevAuthInfo => {
          let somethingChanged = false;
          for (const key in response.data) {
            if (prevAuthInfo[key] !== response.data[key]) {
              somethingChanged = true;
              break;
            }
          }
          if (!somethingChanged) {
            return prevAuthInfo;
          }
          return {
            ...prevAuthInfo,
            ...response.data
          };
        });
      })
      .catch(error => {
        console.error(error, 'An error occurred while fetching user data');
      });
  }, []);

  useEffect(() => {
    if (authInfo && authInfo.token) {
      refreshUserData();
    }
  }, []);

  const onGsiLoad = useCallback(() => {
    setGoogleSignInLibraryLoaded(true);
  }, [setGoogleSignInLibraryLoaded]);

  useScript('https://accounts.google.com/gsi/client', onGsiLoad);

  const canAccessPremiumGames = useCallback((authInfo) => {
    if (!authInfo) {
      return false;
    }
    return authInfo.hasPremiumPlan || authInfo.role === 'ADMIN';
  }, []);

  const value = {
    authInfo: authInfo ? authInfo : null,
    clientId: clientId ? clientId : null,
    onLogin: handleLogin,
    onLogout: handleLogout,
    refreshUserData: refreshUserData,
    googleSignInLibraryLoaded: googleSignInLibraryLoaded,
    canAccessPremiumGames: canAccessPremiumGames
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any,
  closeMenu: PropTypes.func
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};