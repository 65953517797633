import './FormSeparator.scss';

function FormSeparator() {

  return (
    <div className="form-separator">
      <div className="form-separator-line"></div>
      <div>OR</div>
      <div className="form-separator-line"></div>
    </div>
  );
}

export default FormSeparator;
