import './EmailLoginForm.scss';
import { useState } from 'react';
import { gtag, validateEmail, validatePassword } from './utils';
import { RequestStatus } from './constants';
import { useAuth } from './AuthProvider';
import { executeRequestWithMinDelay } from 'common-web/src/js/helper';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthEmailField from './AuthEmailField';
import AuthPasswordField from './AuthPasswordField';
import AppButton from './AppButton';

function EmailLoginForm({ closeMenu }) {

  const [loginStatus, setLoginStatus] = useState(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [showFullEmailForm, setShowFullEmailForm] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const {
    clientId,
    onLogin
  } = useAuth();
  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const navigate = useNavigate();

  const continueWithEnteredEmail = (event) => {
    event.preventDefault();

    gtag('event', 'continue_login_to_password_click', {
      'unique_client_id': clientId
    });

    let emailResult = validateEmail(email);
    setEmailError(emailResult);

    if (emailResult === null) {
      setShowFullEmailForm(true);
    }
  };

  const handleEmailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const validateEmailForm = () => {
    let emailResult = validateEmail(email);
    setEmailError(emailResult);

    let passwordResult = validatePassword(password);
    setPasswordError(passwordResult);

    return emailResult === null && passwordResult === null;
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    gtag('event', 'login_with_email_click', {
      'unique_client_id': clientId
    });
    setAuthenticationFailed(false);

    const isValid = validateEmailForm();
    if (!isValid) {
      return;
    }
    const request = {
      email: email,
      password: password,
      clientId: clientId
    };
    await executeRequestWithMinDelay({
      executeRequest: () => axios.post('/api/login', request),
      onRequestStatusUpdate: setLoginStatus,
      onError: (error) => {
        gtag('event', 'failed_login_with_email', {
          'unique_client_id': clientId
        });
        if (error.response && error.response.status === 401) {
          setAuthenticationFailed(true);
          setLoginStatus(RequestStatus.SUCCESS);
        } else {
          console.error(error, 'An error occurred while executing login request');
          setLoginStatus(RequestStatus.ERROR);
        }
      },
      onSuccess: (response) => {
        gtag('event', 'successful_login_with_email', {
          'unique_client_id': clientId
        });
        onLogin(response.data, 'EMAIL');
      }
    });
  };

  const openSignUpPage = (event) => {
    event.preventDefault();
    gtag('event', 'open_sign_up_click', {
      'unique_client_id': clientId
    });
    closeMenu();
    navigate('/sign-up');
  };

  const openResetPasswordPage = (event) => {
    event.preventDefault();
    gtag('event', 'forgot_password_click', {
      'unique_client_id': clientId
    });
    closeMenu();
    if (email.trim() !== '') {
      navigate('/request-reset-password', { state: { email: email } });
    } else {
      navigate('/request-reset-password');
    }
  };

  const continueToTheNextStep = (event) => {
    if (loginStatus !== RequestStatus.IN_PROGRESS) {
      if (!showFullEmailForm) {
        return continueWithEnteredEmail(event);
      } else {
        return handleLogin(event);
      }
    }
  };

  return (
    <div className="email-auth-form">
      <AuthEmailField
        emailError={emailError}
        email={email}
        handleEmailChange={handleEmailChange}
        handleEnterClick={continueToTheNextStep}
      />
      {showFullEmailForm &&
        <div className="supplementary-email-auth-form-part">
          <AuthPasswordField
            passwordError={passwordError}
            password={password}
            handlePasswordChange={handlePasswordChange}
            handleEnterClick={continueToTheNextStep}
          />
        </div>
      }
      <div className="auth-continue-button-wrapper">
        {authenticationFailed &&
          <div className="form-error-message">Incorrect email or password.</div>}
        {loginStatus === RequestStatus.ERROR &&
          <div className="form-error-message">Oops, something went wrong. Please try again.</div>}
        <AppButton
          onClick={continueToTheNextStep}
          requestInProgress={loginStatus === RequestStatus.IN_PROGRESS}
        />
      </div>
      {showFullEmailForm &&
        <div className="forgot-password-link-wrapper">
          <a
            className="forgot-password-link"
            href="/request-reset-password"
            onClick={openResetPasswordPage}
          >
            Forgot password?
          </a>
        </div>
      }
      <div className="auth-form-hint">
        <div className="auth-form-hint-question">Don&apos;t have an account?</div>
        &nbsp;
        <a
          href="/sign-up"
          className="auth-form-hint-link"
          onClick={openSignUpPage}
        >
          Sign Up
        </a>
      </div>
    </div>
  );
}

EmailLoginForm.propTypes = {
  closeMenu: PropTypes.func
};

export default EmailLoginForm;
