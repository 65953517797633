import {
  MAXIMUM_EMAIL_LENGTH,
  MAXIMUM_PASSWORD_LENGTH,
  MINIMUM_PASSWORD_LENGTH
} from './constants';

const emailRegexp = new RegExp('^\\S+@\\S+\\.\\S+$');

export function validateEmail(email) {
  let emailError = null;
  if (email.length === 0) {
    emailError = { isEmpty: true };
  } else if (email.length > MAXIMUM_EMAIL_LENGTH) {
    emailError = { tooLong: true };
  } else if (!emailRegexp.test(email)) {
    emailError = { wrongPattern: true };
  }

  return emailError;
}

export function validatePassword(password) {
  let passwordError = null;
  if (password.length === 0) {
    passwordError = { isEmpty: true };
  } else if (password.length < MINIMUM_PASSWORD_LENGTH) {
    passwordError = { tooShort: true };
  } else if (password.length > MAXIMUM_PASSWORD_LENGTH) {
    passwordError = { tooLong: true };
  }

  return passwordError;
}

export function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}
