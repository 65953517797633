import './GameRatingSelector.scss';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import axios from 'axios';
import { gtag } from './utils';
import { useAuth } from './AuthProvider';

const MAX_RATING = 5;

function GameRatingSelector({ gameId }) {

  const [rating, setRating] = useState(0);
  const [isSavingRating, setSavingRating] = useState(false);
  const { clientId } = useAuth();

  const saveRating = useCallback((newRating) => {
    setSavingRating(true);
    gtag('event', 'game_rating_selection', {
      'game_id': gameId,
      'rating': newRating,
      'unique_client_id': clientId
    });
    const originalRating = rating;
    setRating(newRating);
    const request = {
      clientId: clientId,
      gameId: gameId,
      rating: newRating,
      clientType: 'web'
    };
    axios.post('/api/game/rate', request)
      .catch((error) => {
        console.error(error, 'An error occurred while saving rating');
        setRating(originalRating);
      })
      .finally(() => {
        setSavingRating(false);
      });
  }, [setSavingRating, rating, setRating, clientId]);

  const removeRating = useCallback(() => {
    setSavingRating(true);
    const originalRating = rating;
    setRating(0);
    const request = {
      clientId: clientId,
      gameId: gameId,
    };
    axios.post('/api/game/remove-rating', request)
      .catch((error) => {
        console.error(error, 'An error occurred while removing rating');
        setRating(originalRating);
      })
      .finally(() => {
        setSavingRating(false);
      });
  }, [setSavingRating, rating, setRating, clientId]);

  const handleRatingClick = useCallback((e, value) => {
    e.preventDefault();
    if (isSavingRating) {
      return;
    }
    if (rating !== value) {
      saveRating(value);
    } else {
      removeRating();
    }
  }, [isSavingRating, rating, saveRating, removeRating]);

  const renderStars = useCallback(() => {
    let stars = [];
    for (let i = 0; i < MAX_RATING; i++) {
      stars.push(<i
        key={i}
        className={`${i + 1 <= rating ? 'fa-solid' : 'fa-regular'} fa-star rating-star`}
        onClick={(e) => handleRatingClick(e, i + 1)}
      />);
    }
    return stars;
  }, [rating, handleRatingClick]);

  return (<div>
    {renderStars()}
  </div>);
}

GameRatingSelector.propTypes = {
  gameId: PropTypes.number
};

export default GameRatingSelector;
