import PropTypes from 'prop-types';

function AppButton({
  onClick,
  requestInProgress,
  label
}) {

  return (
    <button
      className="app-button"
      onClick={onClick}
      disabled={requestInProgress}
    >
      {label ? label : 'Continue'}
      {requestInProgress &&
        <i className="fa-solid fa-spinner loading-spinner"></i>}
    </button>
  );
}

AppButton.propTypes = {
  onClick: PropTypes.func,
  requestInProgress: PropTypes.bool,
  label: PropTypes.string
};

export default AppButton;
