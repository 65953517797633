import './ManagePlan.scss';
import { useAuth } from './AuthProvider';
import { executeRequestWithMinDelay } from 'common-web/src/js/helper';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { RequestStatus } from './constants';
import MembershipInfo from './MembershipInfo';

function ManagePlan() {

  const { authInfo } = useAuth();
  const [checkoutSessionCreationStatus, setCheckoutSessionCreationStatus] = useState(null);

  const hasPremium = () => {
    if (!authInfo) {
      return false;
    }
    return authInfo.hasPremiumPlan;
  };

  const subscribeToPremium = async (e) => {
    e.preventDefault();
    const request = {
      'baseUrl': window.location.origin
    };
    await executeRequestWithMinDelay({
      executeRequest: () => axios.post('/api/payments/create-checkout-session', request),
      onRequestStatusUpdate: setCheckoutSessionCreationStatus,
      onSuccess: (response) => {
        window.location.href = response.data.url;
      }
    });
  };

  useEffect(() => {
    document.title = 'Manage Plan';
  }, []);

  return <div className="manage-plan-page">
    <div className="manage-plan-page-title appearing-element">Choose Your Plan</div>
    <div className="plans-container appearing-element">
      <div className="plan-container">
        <div className="plan-name">Basic</div>
        <div className="plan-price">
          <div className="price-label">$0</div>
          <div className="price-time-interval">/ week</div>
        </div>
        <div className="plan-features">
          <div className="plan-feature">
            <i className="fa-solid fa-check"></i> Access to all free dilemmas
          </div>
        </div>
        {!hasPremium() &&
          <button
            className="app-button manage-plan-btn"
            disabled={true}
          >
            Current Plan
          </button>
        }
      </div>
      <div className="plan-container">
        <div className="plan-name">Premium</div>
        <div className="plan-price">
          <div className="price-label">$5.99</div>
          <div className="price-time-interval">/ week</div>
        </div>
        <div className="plan-features">
          <div className="plan-feature">
            <i className="fa-solid fa-check"></i> Access to all free dilemmas
          </div>
          <div className="plan-feature">
            <i className="fa-solid fa-check"></i> Access to all premium dilemmas
          </div>
          <div className="plan-feature">
            <i className="fa-solid fa-check"></i> Ad-free experience
          </div>
          <div className="plan-feature">
            <i className="fa-solid fa-check"></i> Cancel anytime
          </div>
        </div>
        {!hasPremium() &&
          <>
            {checkoutSessionCreationStatus === RequestStatus.ERROR &&
              <div className="form-error-message">Oops, something went wrong. Please try
                again.</div>}
            <button
              onClick={subscribeToPremium}
              className="app-button manage-plan-btn"
              disabled={checkoutSessionCreationStatus === RequestStatus.IN_PROGRESS}
            >
              Subscribe Now
              {checkoutSessionCreationStatus === RequestStatus.IN_PROGRESS &&
                <i className="fa-solid fa-spinner loading-spinner"></i>}
            </button>
          </>
        }
        {hasPremium() &&
          <button
            className="app-button manage-plan-btn"
            disabled={true}
          >Current Plan</button>
        }
      </div>
    </div>
    {hasPremium() && <MembershipInfo />}
  </div>;
}

export default ManagePlan;
